import { Actions } from '@twilio/flex-webchat-ui';
import * as FlexWebChat from '@twilio/flex-webchat-ui';
import { connect } from 'react-redux';
import { showModal } from '../../../store/reducers/endChatModal';
import { RootState } from '../../../store';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { CustomButton } from '../../atoms/CustomButton';
import { Flex } from '@livelo/alchemy-web';
import * as Tokens from '@livelo/alchemy-tokens';
import { pushDataLayer } from "../../../utils/gtmLiv";

interface LivButtonsProps {
  dispatch: any;
  channelSid: string;
  livButtonsRestore: MutableRefObject<any>;
}

export const LivButtons: React.FC<LivButtonsProps> = ({
  dispatch,
  channelSid,
  livButtonsRestore,
}) => {
  const options = ['Já tenho o Clube', 'Saber mais'];
  //   useEffect(() => {
  //     return () => {
  //       if (removeCleanup.current) {
  //         removeCleanup.current();
  //       }
  //     };
  //   }, []);

  const sendMessage = useCallback(
    async (text: string) => {
      switch (text) {
        case 'Saber mais':
          //Evento para tagueamneto do GA para o botão "Sim" da Liv Proativa
          pushDataLayer({
            event: "event",
            eventCategory: "livelo:webchat",
            eventAction: "clique:botao",
            eventLabel: "liv-vendedora-clube" + ":" + "Saber mais",
          });
          Actions.addListener('afterSendMessage', () => {
            livButtonsRestore.current =
              FlexWebChat.MessageList.Content.remove('liv-buttons');
          });

          await Actions.invokeAction('SendMessage', {
            channelSid,
            body: text,
          });

          break;
        case 'Já tenho o Clube':
          //Evento para tagueamneto do GA para o botão "Não" da Liv Proativa
          pushDataLayer({
            event: "event",
            eventCategory: "livelo:webchat",
            eventAction: "clique:botao",
            eventLabel: "liv-vendedora-clube" + ":" + "Já tenho o Clube",
          })
          FlexWebChat.AppConfig.current().flexFlowSid =
            process.env.REACT_APP_FLEX_FLOW_SID;

          // dispatch(showModal());
          await Actions.invokeAction('SendMessage', {
            channelSid,
            body: text,
          });

          break;
        default:
          break;
      }
    },
    [livButtonsRestore, channelSid, dispatch],
  );

  return (
    <Flex
      mt="stack._3XS"
      flexDirection="row"
      flexWrap="wrap"
      width="100%"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: Tokens.SpacingBase,
      }}
    >
      {options.map((option, index) => (
        <CustomButton key={index} onClick={() => sendMessage(option)} size="SM">
          {option}
        </CustomButton>
      ))}
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    channelSid: state.flex.session.channelSid,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LivButtons);
